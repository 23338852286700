export default [
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 30,
    header: {
      type: 'text',
      caption: 'Client ID',
    },
    cell: {
      type: 'text',
      value: (e) => e.clientId,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Key name',
    },
    cell: {
      type: 'text',
      value: (e) => e.appName,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Date',
    },
    cell: {
      type: 'slot',
      name: 'date',
    },
  },
  // {
  //   width: 10,
  //   header: {
  //     type: 'text',
  //     caption: 'Withdrawal fee',
  //   },
  //   cell: {
  //     type: 'slot',
  //     name: 'withdrawalFee',
  //   },
  // },
  {
    width: 45,
    // width: 35,
    header: {
      type: 'text',
      caption: 'Permissions',
    },
    cell: {
      type: 'slot',
      name: 'permissions',
    },
  },
  {
    width: 5,
    header: {
      type: 'text',
      caption: '',
    },
    cell: {
      type: 'slot',
      name: 'change',
    },
  },
];
