//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import constant from '~/const';

export default {
  props: {
    data: { type: Object, required: true },
  },

  data() {
    return {
      constant,
    };
  },

  computed: {
    tradesResult() {
      const { data } = this;
      try {
        const parsed = JSON.parse(data.result);
        const toRouterResult = Object.entries(JSON.parse(parsed) ?? {}).map((item) => {
          if (item[0] === 'TradeId') {
            return {
              name: 'trading',
              queryKey: 'TradesSearchString',
              id: item[1],
            };
          }
          if (item[0] === 'AdvertismentId') {
            return {
              name: 'p2p-offers-and-deals',
              queryKey: 'OffersSearchString',
              id: item[1],
            };
          }
          return '';
        });
        return toRouterResult;
      } catch (error) {
        return [];
      }
    },
  },
};
