//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import UserApiKeys from '~/views/UserApi/UserApiKeys/UserApiKeys.vue';
import UserApiHistory from '~/views/UserApi/UserApiHistory/UserApiHistory.vue';

export default {
  components: {
    UserApiKeys,
    UserApiHistory,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadUserApiKeys(),
      this.loadUserApiHistory(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('userApiKeys', {
      loadUserApiKeys: 'loadData',
    }),
    ...mapActions('userApiHistory', {
      loadUserApiHistory: 'loadData',
    }),
  },
};
