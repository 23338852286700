var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-api-history"},[_c('div',{staticClass:"user-api-history__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages},on:{"input":_vm.setPage}})],1),_c('div',{staticClass:"user-api-history__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px","show-extra":""},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"dateCreated",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateCreated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$utils.getTime(row.dateCreated)))])])]}},{key:"id",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}},{key:"keyName",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.appName)+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.enabled)?_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$t('Enabled'))+" ")]):_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('Disabled'))+" ")])]}},{key:"ip",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.ipAddress)+" ")]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getCaption(_vm.constant.api.ACTION_TYPE_LIST, row.actionType))+" ")]}},{key:"result",fn:function(ref){
var row = ref.row;
return [(row.success)?_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$t('Success'))+" ")]):_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('Failure'))+" ")])]}},{key:"userAgent",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getUserBrowser(row.userAgent))+" ")]}},{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('UserApiHistoryExtra',{attrs:{"data":row}})]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }