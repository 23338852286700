export default [
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Date',
    },
    cell: {
      type: 'slot',
      name: 'dateCreated',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'slot',
      name: 'id',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Key name',
    },
    cell: {
      type: 'slot',
      name: 'keyName',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'IP',
    },
    cell: {
      type: 'slot',
      name: 'ip',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Type',
    },
    cell: {
      type: 'slot',
      name: 'type',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Result',
    },
    cell: {
      type: 'slot',
      name: 'result',
    },
  },
  // {
  //   width: 10,
  //   header: {
  //     type: 'text',
  //     caption: 'Request',
  //   },
  //   cell: {
  //     type: 'slot',
  //     name: 'request',
  //   },
  // },
  {
    width: 20,
    header: {
      type: 'text',
      caption: 'User agent',
    },
    cell: {
      type: 'slot',
      name: 'userAgent',
    },
  },
];
