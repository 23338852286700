//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './UserApiKeysFilters.vue';
import Pagination from '~/components/Pagination.vue';

import tableConfig from './tableConfig';
import constant from '~/const';

export default {
  components: {
    Filters,
    Pagination,
  },

  data() {
    return {
      tableConfig,
      loader: false,
      updatingApiKey: {
        modal: false,
        id: 0,
        input: {
          appName: '',
          active: false,
          claimList: [],
        },
      },
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('userApiKeys', ['page', 'pages', 'data', 'sort']),

    activeList() {
      return [
        {
          id: true,
          caption: 'Enabled',
        },
        {
          id: false,
          caption: 'Disabled',
        },
      ];
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('userApiKeys', {
      loadDataAction: 'loadData',
      updateApiKeyAction: 'updateApiKey',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    async loadData(data = {}) {
      this.loader = true;
      this.setGeneralProgress(true);
      await this.loadDataAction(data);
      this.loader = false;
      this.setGeneralProgress(false);
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    getPermissionList(list) {
      const { CLIENT_CLAIM_TYPE_LIST } = constant.api;
      const filteredList = CLIENT_CLAIM_TYPE_LIST.filter((e) => list.includes(e.id));
      const permissionList = filteredList.map((e) => e.caption);
      return permissionList.join(', ');
    },

    change(row) {
      this.updatingApiKey.input.appName = row.appName;
      this.updatingApiKey.input.active = row.active;
      this.updatingApiKey.input.claimList = row.permissionList;
      this.updatingApiKey.id = row.id;
      this.updatingApiKey.modal = true;
    },

    async updateApiKey() {
      const { appName, active, claimList } = this.updatingApiKey.input;
      const { id } = this.updatingApiKey;
      const data = {
        keyId: id,
        appName,
        active,
        claimList,
      };

      this.setGeneralProgress(true);
      try {
        await this.updateApiKeyAction(data);
        this.loader = true;
        await this.loadDataAction({});
        this.loader = false;
        this.updatingApiKey.modal = false;
        this.setSuccessNotification('API key updated');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
    },
  },
};
