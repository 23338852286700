var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-api-keys"},[_c('div',{staticClass:"user-api-keys__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('Pagination',{attrs:{"page":_vm.page,"pages":_vm.pages},on:{"input":_vm.setPage}})],1),_c('div',{staticClass:"user-api-keys__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px"},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var row = ref.row;
return [(row.active)?_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$t('Active'))+" ")]):_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('Disabled'))+" ")])]}},{key:"date",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateCreated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$utils.getTime(row.dateCreated))+" ")])]}},{key:"withdrawalFee",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.withdrawalFee || '–')+" ")]}},{key:"permissions",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getPermissionList(row.permissionList))+" ")]}},{key:"change",fn:function(ref){
var row = ref.row;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.change(row)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('Pagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1),_c('v-dialog',{attrs:{"max-width":"380"},model:{value:(_vm.updatingApiKey.modal),callback:function ($$v) {_vm.$set(_vm.updatingApiKey, "modal", $$v)},expression:"updatingApiKey.modal"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline mb-3"},[_vm._v(" "+_vm._s(_vm.$t('API key settings'))+" ")]),_c('v-card-text',[_c('v-text-field',{attrs:{"dense":"","hide-details":"","outlined":"","placeholder":" ","label":_vm.$t('Name')},model:{value:(_vm.updatingApiKey.input.appName),callback:function ($$v) {_vm.$set(_vm.updatingApiKey.input, "appName", $$v)},expression:"updatingApiKey.input.appName"}})],1),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.activeList,"item-text":function (e) { return e.caption; },"item-value":function (e) { return e.id; },"value":_vm.updatingApiKey.input.active,"dense":"","hide-details":"","outlined":"","placeholder":" ","label":_vm.$t('Status')},on:{"input":function($event){_vm.updatingApiKey.input.active = $event}}})],1),_c('v-card-text',{staticClass:"mb-6"},_vm._l((_vm.constant.api.CLIENT_CLAIM_TYPE_LIST),function(claim){return _c('v-checkbox',{key:claim.id,staticClass:"mt-0",attrs:{"value":claim.id,"label":claim.caption,"hide-details":""},model:{value:(_vm.updatingApiKey.input.claimList),callback:function ($$v) {_vm.$set(_vm.updatingApiKey.input, "claimList", $$v)},expression:"updatingApiKey.input.claimList"}})}),1),_c('v-card-text',[_c('v-btn',{attrs:{"color":"success","outlined":""},on:{"click":_vm.updateApiKey}},[_vm._v(" "+_vm._s(_vm.$t('Update'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }