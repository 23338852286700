//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './UserApiHistoryFilters.vue';
import UserApiHistoryExtra from './UserApiHistoryExtra.vue';

import tableConfig from './tableConfig';
import constant from '~/const';

export default {
  components: { Filters, UserApiHistoryExtra },

  data() {
    return {
      tableConfig,
      selection: [],
      loader: false,
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('userApiHistory', ['page', 'pages', 'data', 'sort']),
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
    ]),
    ...mapActions('userApiHistory', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    getUserBrowser(value) {
      const dataObj = this.$utils.getParsedUA(value);
      if (dataObj.browser && dataObj.os) {
        return `
          ${dataObj.browser.name || this.$t('Unknown browser')},
          ${dataObj.os.name || this.$t('unknown OS')}
        `;
      }
      return '';
    },
  },
};
